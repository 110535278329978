import "./employers.scss";

import * as React from 'react'
import {useTranslation} from "react-i18next";
import Layout from "../layouts";
import {Banner} from "../components/Banner";
import {Alert, Button, ButtonGroup, Col, Container, Form, FormGroup, Input, Label, Media, Row} from "reactstrap";
import {MainContainer} from "../components/MainContainer";
import {PageSection} from "../components/PageSection";
import {ArticleContainer} from "../components/ArticleContainer";
import {ArticleSection} from "../components/ArticleSection";
import {PageProps} from "gatsby";
import {TileContainer} from "../components/TileContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FormEvent} from "react";

interface FormModel {
    firstName?: string,
    lastName?: string,
    email?: string,
    message?: string,
    company?: string,
}


export default function Employers(props: PageProps){
    const {t} = useTranslation("employers");

    const [error, setError] = React.useState<string>("");
    const [model, setModel] = React.useState<FormModel>({});
    const [submitted, setSubmitted] = React.useState<boolean>(false);

    const change = (changeModel: FormModel) => {
        setModel({...model, ...changeModel});
    }

    // submit form
    const  handleSubmit = (e: FormEvent) => {
        const errors: string[] = [];
        if (!model.firstName || model.firstName === "") {
            errors.push(t("formName", "First Name"));
        }
        if (!model.lastName || model.lastName === "") {
            errors.push(t("formSurname", "Last Name"));
        }
        if (!model.email || model.email === "") {
            errors.push(t("formEmail", "Email"));
        }
        if (!model.message || model.message === "") {
            errors.push(t("formMessage", "Message"));
        }

        if (errors.length > 0) {
            setError(`${t("formValidationMessage", "")} ${errors.slice(0, 3).join(", ")}`);
            return;
        }

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: JSON.stringify({ "form-name": "employers-contact", ...model })
        })
            .then(() => {setSubmitted(true); setError("")})
            .catch(error => setError(error));

        e.preventDefault();
    };

    return (
        (
            <Layout location={props.location}>
                <Banner background={"employers"}>
                    <Media className={"logo"}/>
                    <div className={"banner-text"}>
                        <h4>{t("bannerHeader", "")}</h4>
                    </div>
                </Banner>
                <MainContainer>
                    <PageSection>
                        <ArticleContainer>
                            <p>{t("paragraph1", "")}</p>
                        </ArticleContainer>
                        <div className={"employers-tile-section"}>
                            <ArticleSection className={"md"} color={"gold"}>
                                <TileContainer color={"gold"}>
                                    <div className={"icon-container"}><FontAwesomeIcon className={"icon"} icon={"user-tie"}/></div>
                                    <Container className={"tile-contents-container"}>
                                        <h5>{t("globalWorkforceHead", "")}</h5>
                                        <ul className={"tile-list"}>
                                            <li>{t("globalWorkforceBullet1", "")}</li>
                                            <li>{t("globalWorkforceBullet2", "")}</li>
                                            <li>{t("globalWorkforceBullet3", "")}</li>
                                        </ul>
                                    </Container>
                                </TileContainer>
                            </ArticleSection>
                            <ArticleSection className={"md"} color={"green"}>
                                <TileContainer color={"green"}>
                                    <div className={"icon-container"}><FontAwesomeIcon className={"icon"} icon={"clipboard-list"}/></div>
                                    <Container className={"tile-contents-container"}>
                                        <h5>{t("externalAdminHead", "")}</h5>
                                        <ul className={"tile-list"}>
                                            <li>{t("externalAdminBullet1", "")}</li>
                                            <li>{t("externalAdminBullet2", "")}</li>
                                            <li>{t("externalAdminBullet3", "")}</li>
                                        </ul>
                                    </Container>
                                </TileContainer>
                            </ArticleSection>
                        </div>
                    </PageSection>
                    <PageSection>
                        <Container>
                            <h3>{t("getInTouch", "Get in touch")}</h3>
                            <p>{t("getInTouchMessage", "")}</p>
                        </Container>
                    </PageSection>
                    <PageSection>
                        <ArticleSection color={"purple"}>
                            {error !== "" && (
                                <Alert color={"danger"}>{error}</Alert>
                            )}
                            {submitted && (<Alert>{t("formSubmitted", "Thanks, we're get back to you shortly!")}</Alert>)}
                            <Form
                                name="employers-contact" method="post" data-netlify="true"
                                onSubmit={(e) => {e.preventDefault(); handleSubmit(e)}}>
                                <input type="hidden" name="form-name" value="employers-contact" />
                                <Row form>
                                    <Col md={6}>
                                        <FormGroup className={"required"}>
                                            <Label htmlFor={"firstName"}>{t("formName", "First Name")}</Label>
                                            <Input type={"text"}
                                                   name={"firstName"}
                                                   onChange={(e) => change({firstName: e.currentTarget.value})}
                                                   value={model.firstName}/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup className={"required"}>
                                            <Label htmlFor={"lastName"}>{t("formSurname", "Last Name")}</Label>
                                            <Input type={"text"}
                                                   name={"lastName"}
                                                   onChange={(e) => change({lastName: e.currentTarget.value})}
                                                   value={model.lastName}/>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Label htmlFor={"company"}>{t("formCompanyName", "Company")}</Label>
                                    <Input type={"text"}
                                           name={"company"}
                                           onChange={(e) => change({company: e.currentTarget.value})}
                                           value={model.company}/>
                                </FormGroup>
                                <FormGroup className={"required"}>
                                    <Label htmlFor={"email"}>{t("formEmail", "Email")}</Label>
                                    <Input type={"text"}
                                           name={"email"}
                                           onChange={(e) => change({email: e.currentTarget.value})}
                                           value={model.email}/>
                                </FormGroup>
                                <FormGroup className={"required"}>
                                    <Label htmlFor={"message"}>{t("formMessage", "Message")}</Label>
                                    <Input type={"textarea"}
                                           message={"message"}
                                           onChange={(e) => change({message: e.currentTarget.value})}
                                           value={model.message}/>
                                </FormGroup>
                                <ButtonGroup className={"form-buttons"}>
                                    <Button type={"submit"} color={"secondary"}>
                                        {t('formSubmit', "Submit")}
                                    </Button>
                                </ButtonGroup>
                            </Form>
                        </ArticleSection>
                    </PageSection>
                </MainContainer>
            </Layout>
        )
    )
}
