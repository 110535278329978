import "./tileContainer.scss";
import React from "react";

export interface ArticleSectionProps {
    children?: React.ReactNode,
    className?: string
    color?: string
}

export const TileContainer = (props: ArticleSectionProps) => {
    const {children, className = "", color = ""} = props;

    return (
        <div className={`tile-container ${color} ${className}`}>
            {children}
        </div>
    )
}

